.card-component-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #0000001f;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
}

.card-component-section {
  display: flex;
  flex-direction: column;
}

.card-component-section-content {
  padding: 16px 12px;
}

.card-component-section-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px 6px 24px;
  height: 50px;
}

.card-component-section-title-title {
  padding: 0;
  line-height: 22px;
}
