.camvio-smooth-animated-1 {
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.camvio-smooth-animated-2 {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.camvio-smooth-animated-3 {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.camvio-smooth-animated-4 {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.camvio-smooth-animated-5 {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
