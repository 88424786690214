.leaflet-marker-popup .leaflet-popup-content-wrapper {
  border-radius: 8px 8px 0px 0px;
}
.leaflet-marker-popup .leaflet-popup-content {
  width: 20px !important;
  margin: 0px;
}

.leaflet-marker-popup .leaflet-popup-tip-container {
  width: 20px;
  height: 14px;
  margin-left: -10px;
}

.leaflet-marker-popup .leaflet-popup-tip {
  width: 18px;
  height: 18px;
}
