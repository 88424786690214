.action-modal-header-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 12px;
  z-index: 1;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.5);
}

.action-modal-header-wrapper .action-modal-header-row {
  display: flex;
  align-items: baseline;
  gap: 16px;
}
.action-modal-header-wrapper .action-modal-header-row.space-between {
  justify-content: space-between;
}

.action-modal-header-wrapper .action-modal-header-title {
  font-size: 28px;
  font-weight: 600;
  color: #0f6cbd;
}

.action-modal-header-wrapper .action-modal-header-btn-group {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  align-items: center;
}

.action-modal-header-wrapper .action-status-badge {
  font-size: 12px;
  color: #fff;
  padding: 6px 12px;
  border-radius: 6px;
}

.action-modal-header-wrapper .action-status-badge.error {
  background-color: #d46b6b;
}

.action-modal-header-wrapper .action-status-badge.pending {
  background-color: #e59805;
}

.action-modal-header-wrapper .action-status-badge.success {
  background-color: #6fae84;
}

.action-modal-header-wrapper .action-header-assignment {
  display: flex;
  gap: 4px;
  align-items: center;
}

.action-modal-header-wrapper .action-header-assignment-type {
  color: #424242;
}

.action-modal-header-wrapper .action-action-button {
  border-radius: 4px;
  padding: 3px 12px;
}
