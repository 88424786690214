.banner-container {
  border-radius: 10px;
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.banner-container.banner-success {
  background-color: #c1dcc0;
  color: #234722;
}
.banner-container.banner-success p {
  color: #234722;
}
.banner-container.banner-success .icon {
  color: #234722;
  fill: #234722;
}
.banner-container.banner-error {
  background-color: #f0c2ba;
  color: #963120;
}
.banner-container.banner-error p {
  color: #963120;
}

.banner-container.banner-error .icon {
  color: #963120;
  fill: #963120;
}
