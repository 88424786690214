.weather-day-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 6px;
  margin: 2px;
  width: calc(90% / 7);
  max-height: 80px;
  border: 1px solid #dcdee6;
  border-radius: 4px;
  background-color: #fafafa;
}

.weather-day-wrapper.future-day {
  background-color: white;
}

.weather-day-wrapper.today {
  background-color: #eefcff;
  border-color: #eefcff;
}

.weather-day-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.weather-day-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: calc(128% - 10px); */
}

.weather-day-wrapper:hover {
  border-color: #96b6ed;
}

.weather-day-temperature {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.weather-day-temperature .max-temp {
  font-size: 20px;
}

.weather-day-temperature .min-temp {
  font-size: 16px;
}

@media (max-width: 1399px) {
  .weather-day-temperature .max-temp {
    font-size: 17px;
  }

  .weather-day-temperature .min-temp {
    font-size: 13px;
  }
}

@media (max-width: 1299px) {
  .weather-day-temperature .max-temp {
    font-size: 12px;
  }

  .weather-day-temperature .min-temp {
    font-size: 10px;
  }
}

@media (max-width: 1199px) {
  .weather-day-temperature .max-temp {
    font-size: 18px;
  }

  .weather-day-temperature .min-temp {
    font-size: 14px;
  }
}

@media (max-width: 799px) {
  .weather-day-temperature .max-temp {
    font-size: 16px;
  }

  .weather-day-temperature .min-temp {
    font-size: 12px;
  }
}
