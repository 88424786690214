.toast-message {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.toast-message .toast-message-title {
  font-weight: 600;
}

.toast-message .toast-message-link {
  width: max-content;
  font-weight: 500;
  text-decoration: none;
}

.toast-message .toast-message-link:hover {
  text-decoration: underline;
}
