.opekepe-sync {
  width: 330px;
}
.opekepe-sync.fullwidth {
  width: 100%;
}
.opekepe-sync .sync-step {
  margin-bottom: 2rem;
}
.opekepe-sync .sync-step.disabled {
  opacity: 0.6;
}
.sync-step-title {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.sync-step-title p,
.sync-step-title * {
  font-weight: 600;
}

.sync-step-title .sync-step-index {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #e3e3e3;
}
.sync-step-content {
  min-height: 10px;
}
.sync-step-content > * {
  margin-top: 0.75rem;
}

.step-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
}
.step-content-row {
  display: flex;
  justify-content: space-between;
}
.step-content-row p:nth-of-type(2) {
  font-weight: 600;
}
