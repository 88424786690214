.verification-inputs {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.verification-inputs input:disabled {
  background-color: #f5f5f5;
  color: #a0a0a0;
  border: 1px solid #ddd;
  cursor: not-allowed;
  opacity: 0.7;
}

.verification-inputs input {
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
