.toggle_sidebar_btn {
  background: rgba(7, 65, 139, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  border: none;
  position: absolute;
  margin-left: 19rem;
  margin-top: 64px;
  cursor: pointer;
  height: 40px;
  width: 23px;
  border-radius: 0 16px 16px 0;
  z-index: 999999999;
}
.toggle_sidebar_btn > * {
  color: white;
}

.sidebar-title {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
}
