.inbox-sidebar-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inbox-sidebar-title {
  font-size: 20px;
  font-weight: 700;
}

.inbox-sidebar-content {
  flex: 1;
  overflow-y: auto;
}

.global-drawer {
  overflow: auto;
}

.global-drawer-background {
  opacity: 0;
}
.global-drawer-background.open {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000088;
}

.global-drawer .inbox-sidebar-item {
  margin-top: 8px;
}

.inbox-sidebar-item.notification-time-title {
  line-height: 20px;
  color: #424242;
}

.inbox-sidebar-item.notification {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
}

.inbox-sidebar-item.notification:hover {
  background-color: #fafafa;
}

.notification .notification-time {
  line-height: 16px;
  color: #424242;
}

.notification .notification-left-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-right: 12px;
}

.notification .notification-content {
  flex: 1;
}
.notification-content.action-notification-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.notification-content .buttons-container {
  display: flex;
  justify-content: stretch;
  gap: 4px;
}

.notification-content .buttons-container button {
  flex: 1;
}

/* Icons */
.icon-read-one {
  background-image: url("../../../assets/icons/check.svg");
}

.icon-read-all {
  background-image: url("../../../assets/icons/double-check.svg");
}

.icon-delete-one {
  background-image: url("../../../assets/icons/medium.svg");
}

.icon-settings-tune {
  background-image: url("../../../assets/icons/tune.svg");
}
