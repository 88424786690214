.field {
  width: calc(100% - 20px);
  background-color: white;
  border: 1px solid #d9d9d9;
  margin: 10px;
  border-radius: 4px;
}

.header {
  margin: 10px 15px 5px 15px;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #184c7b;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-with-icon {
  margin: 10px 15px 5px 15px;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #184c7b;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.line {
  width: 100%;
  border-top: 1px solid #d9d9d9;
}

.text {
  display: flex;
  margin: 20px;
  justify-content: flex-start;
}
