.action-modal-comments-wrapper {
  width: 100%;
}
.action-modal-comments-title-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.action-modal-comments-wrapper .submit-comment-btn {
  align-self: flex-end;
}

.comments-section {
  margin-top: 16px;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.comments-section .toggle-more-btn {
  all: unset;
  cursor: pointer;
  margin-right: 16px;
}

.toggle-more-btn:hover {
  font-weight: 600;
  text-decoration: underline;
}

ul.comments-list {
  width: 100%;
  list-style-type: none;
}

ul.comments-list li {
  padding-bottom: 16px;
}

.comment-meta {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 6px;
}

p.comment-text {
  font-size: 13px;
}
