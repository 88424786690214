@font-face {
  font-family: "Roboto", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
}
:root {
  --main-color: #444c99;
  --disabled-color: #9d9d9d;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

body {
  background-color: rgb(246, 246, 246);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #c2bfbf;
  border-radius: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Icons */
.icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Unstyled button */
.btn-unstyled {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.btn-unstyled:disabled {
  cursor: default;
  opacity: 0.5;
}

/* Truncate text */
.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}
